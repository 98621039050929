import { request } from '@labServices/apis/arogga-api';
import querystring from 'qs';
import { IC_DoctorWishList, IC_DoctorWishListBody } from '../types/doctor/type.addDoctorToWishList';
import { IG_DoctorInfo } from '../types/doctor/type.getAvailableDoctors';
import { IG_DoctorsSearchCriteria } from '../types/doctor/type.getDoctersSearchCriteria';
import { IG_DoctorExperience } from '../types/doctor/type.getDoctorExperiences';
import { IG_DoctorProfile } from '../types/doctor/type.getDoctorProfile';
import { IG_DoctorSchedule } from '../types/doctor/type.getDoctorSchedule';
import { IG_DoctorSearch } from '../types/doctor/type.getDoctorSearch';
import { IG_DoctorSpecialityInfo } from '../types/doctor/type.getDoctorSpecialities';
import { IG_DoctorWishList } from '../types/doctor/type.getDoctorWishList';
import { IG_DoctorRecentViewInfo } from '../types/doctor/type.getRecentViewDoctors';
import { IG_DoctorTreatmentCategoryInfo } from '../types/doctor/type.getTreatmentCategories';
import { I_ResponseDataArray, I_ResponseDataObj } from '../types/type.doc-consultation-apis';

export const createDoctorProfile = (body: any) => {
	return request({
		endpoint: `doctor/api/v1/public/doctors`,
		method: 'POST',
		body: body,
		customOptions: {
			isFormData: true
		}
	});
};

export const getAvailableDoctors = (): Promise<I_ResponseDataArray<IG_DoctorInfo>> => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/available`,
		method: 'GET',
		revalidateTime: 0
	});
};

export const getDoctorSpecialties = (): Promise<I_ResponseDataArray<IG_DoctorSpecialityInfo>> => {
	return request({
		endpoint: `doctor/api/v1/public/speciality`,
		method: 'GET'
	});
};

export const getTreatmentCategories = (): Promise<I_ResponseDataArray<IG_DoctorTreatmentCategoryInfo>> => {
	return request({
		endpoint: `doctor/api/v1/public/treatment-category`,
		method: 'GET'
	});
};

export const getRecentViewDoctors = (): Promise<I_ResponseDataArray<IG_DoctorRecentViewInfo>> => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/recent-view`,
		method: 'GET'
	});
};

export const getDoctorProfile = (id: string): Promise<I_ResponseDataObj<IG_DoctorProfile>> => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/${id}`,
		method: 'GET'
	});
};

export const getDoctorExperience = (id: string): Promise<I_ResponseDataArray<IG_DoctorExperience>> => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/experience/${id}`,
		method: 'GET'
	});
};

export const getDoctorSchedule = (id: string): Promise<I_ResponseDataArray<IG_DoctorSchedule>> => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/schedule/${id}`,
		method: 'GET'
	});
};

export const getFilteredDoctors = (q: object): Promise<I_ResponseDataArray<IG_DoctorSearch>> => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `lab-search/api/v1/doctor-search?${qs}`,
		method: 'GET'
	});
};

export const getDoctorsSearchCriteria = (): Promise<I_ResponseDataObj<IG_DoctorsSearchCriteria>> => {
	return request({
		endpoint: `lab-search/api/v1/doctor-search/criteria`,
		method: 'GET'
	});
};

interface IU_OnlineVisibility {
	isOnline: boolean;
	isDoctor: boolean;
}
export const updateOnlineVisibility = (body: IU_OnlineVisibility) => {
	const { isOnline, isDoctor } = body;
	const endpoint = isDoctor
		? `doctor/api/v1/public/doctors/online-visibility`
		: `doc-consultation/api/v1/users/online-visibility`;

	return request({
		endpoint: endpoint,
		method: 'PUT',
		body: {
			isOnline
		}
	});
};

export const addDoctorToWishList = (body: IC_DoctorWishListBody): Promise<I_ResponseDataObj<IC_DoctorWishList>> => {
	const { doctorUqid } = body;
	return request({
		endpoint: `doctor/api/v1/public/doctors/wishlist`,
		method: 'POST',
		body: {
			doctorUqid
		}
	});
};

export const getDoctorWishList = (q: object): Promise<I_ResponseDataArray<IG_DoctorWishList>> => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `doctor/api/v1/public/doctors/wishlist?${qs}`,
		method: 'GET'
	});
};

export const deleteDoctorFromWishList = (wishlistId: string) => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/wishlist/${wishlistId}`,
		method: 'DELETE'
	});
};
