import { agoraConfig } from '@/services/ecommerce/apis/config.ts';
import { RtmTokenBuilder } from 'agora-token';

export function generateRTMToken(uid) {
	const uId = typeof uid === 'number' ? uid.toString() : uid;
	// (Math.random() * (1 - 100) + 1).toString();

	const token = RtmTokenBuilder.buildToken(
		agoraConfig?.appId,
		agoraConfig?.appCertificate,
		uId,
		agoraConfig?.tokenExpiryTime
	);
	return token;
}
