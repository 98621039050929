'use client';

import { agoraConfig } from '@/services/ecommerce/apis/config';
import { generateRTMToken } from '@docConsultationServices/utils/generateRTMToken';
// import AgoraRTM from 'agora-rtm-sdk';
import useAuth from '@/contexts/AuthProvider';
import useToggle from '@/services/lab-test/hooks/use-toggle';
import { createContext, useContext, useEffect, useState } from 'react';
import { I_MessageCallState } from '../hooks/type.useAgoraSignalEvents';

export const AgoraSignalingContext = createContext<any>(null);

let rtm = null;
let AgoraRTM = null;

export const AgoraSignalingProvider = ({ children }: { children: React.ReactNode }) => {
	const [openOnlineOfflineModal, handleOpenOnlineOfflineModal, handleCloseOnlineOfflineModal] = useToggle();
	const [isDoctorLoggedIn, setIsDoctorLoggedIn] = useState<boolean>(false);
	const [isPatientLoggedIn, setIsPatientLoggedIn] = useState(false);

	const [eventCall, setEventCall] = useState({});
	const [messageCall, setMessageCall] = useState<Partial<I_MessageCallState>>({});

	const { user } = useAuth();

	useEffect(() => {
		if (typeof window !== 'undefined') {
			(async () => {
				const rtmSdk = await import('agora-rtm-sdk');
				AgoraRTM = rtmSdk;

				if (user?.u_id && !user?.u_is_doctor) {
					await signalPatientLogin(user?.u_id.toString());
				}
			})();
		}
	}, [user?.u_id, user?.u_is_doctor]);

	const setupSignalingEngine = (uid) => {
		try {
			if (typeof window !== 'undefined') {
				rtm = new AgoraRTM.RTM(agoraConfig?.appId, uid);

				signalingPresenceEvent();
				signalMessageEvent();
				getConnectionStatus();

				console.log('Signal Engine:', rtm);
			}
		} catch (error) {
			console.log('Error:', error);
		}
	};

	const subscribeChannel = async () => {
		try {
			const subscribeOptions = {
				withMessage: true,
				withPresence: true, // Enable presence notifications
				withMetadata: true,
				withLock: true
			};

			await rtm.subscribe(agoraConfig?.channelName, subscribeOptions);
		} catch (error) {
			console.log(error);
		}
	};

	const unsubscribeChannel = async (channelName) => {
		try {
			await rtm.unsubscribe(channelName);
		} catch (error) {
			console.log(error);
		}
	};

	const signalDoctorLogin = async (uid) => {
		try {
			await setupSignalingEngine(uid);

			if (!!rtm) {
				await rtm.login({
					token: generateRTMToken(uid)
				});
				await subscribeChannel();
				setIsDoctorLoggedIn(true);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const signalPatientLogin = async (uid) => {
		try {
			await setupSignalingEngine(uid);
			if (!!rtm) {
				await rtm.login({
					token: generateRTMToken(uid)
				});
				await subscribeChannel();
				setIsPatientLoggedIn(true);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const signalLogout = async () => {
		try {
			if (!rtm) return;
			await rtm.logout();
		} catch (error) {
			console.log('[LOGOUT]:', error);
		}
	};

	const signalingPresenceEvent = () => {
		if (!rtm) return;

		rtm.addEventListener('presence', (event) => {
			if (event.eventType === 'SNAPSHOT') {
				console.log('[SNAPSHOT Event]:', event);
				setEventCall(event);
			}
			// Update the queue state based on the event type (REMOTE_JOIN)
			else if (event.eventType === 'REMOTE_JOIN') {
				console.log('[REMOTE_JOIN Event]:', event);
				setEventCall(event);
			}
			// Update the queue state based on the event type (REMOTE_LEAVE )
			else if (event.eventType === 'REMOTE_LEAVE') {
				console.log('[REMOTE_LEAVE Event]:', event);
				setEventCall(event);
			} else if (event.eventType === 'REMOTE_STATE_CHANGED') {
				console.log('[REMOTE_STATE_CHANGED Event]:', event);
				setEventCall(event);
			} else {
				console.log('Presence Event:', event);
				setEventCall(event);
			}
		});
	};

	// TODO: Connection state changed event handler.
	const getConnectionStatus = async () => {
		rtm.addEventListener('status', (event) => {
			console.log('Status INFO:', JSON.stringify(event));
		});

		rtm.addEventListener('linkState', (event) => {
			console.log('LinkStatus INFO:', JSON.stringify(event));
		});
	};

	// TODO: Publish Message separate function.

	const signalMessageEvent = async () => {
		try {
			if (!rtm) return;
			rtm.addEventListener('message', (event) => {
				console.log('Message Event:', event);
				if (event) {
					setMessageCall({ ...event, message: { ...JSON.parse(event.message) } });
				}
			});
		} catch (error) {
			console.log('Error:', error);
		}
	};

	const getUserSubscribeChannels = async (uId) => {
		try {
			if (!rtm) return;

			const userChannels = await rtm.presence.getUserChannels(uId);
			console.log('userChannels:', userChannels);
		} catch (error) {
			console.log('Error:', error);
		}
	};

	const getOnlineUsersInChannel = async (channelName) => {
		try {
			if (!rtm) return;

			const onlineUsers = await rtm.presence.getOnlineUsers(channelName, 'MESSAGE', {
				includedUserId: true
			});
			console.log('Online Users:', onlineUsers);
		} catch (error) {
			console.log('Error:', error);
		}
	};

	const sendCallInvitation = async (doctorId, remoteUid) => {
		try {
			if (!rtm) return;

			await rtm.subscribe(`inbox_d${doctorId}_p${remoteUid}`);

			const message = JSON.stringify({
				type: 'call-invitation',
				doctor_uid: doctorId,
				patient_uid: remoteUid,
				channelName: `inbox_d${doctorId}_p${remoteUid}`
			});

			// Send a private message to the user with the UserID
			await rtm.publish(agoraConfig?.channelName, message);
		} catch (error) {
			console.error('Error sending call invitation:', error);
		}
	};

	const acceptCallInvitation = async (privateChannel) => {
		try {
			if (!rtm) return;

			await rtm.subscribe(privateChannel?.message?.channelName);

			const message = JSON.stringify({
				type: 'call-invitation-accepted',
				doctor_uid: privateChannel?.message?.doctor_uid,
				patient_uid: privateChannel?.message?.patient_uid,
				channelName: privateChannel?.message?.channelName
			});

			// Send a private message to the user with the UserID
			await rtm.publish(privateChannel?.message?.channelName, message);
		} catch (error) {
			console.error('Error accepting call invitation:', error);
		}
	};

	const declineCallInvitation = async (privateChannel) => {
		try {
			// if (!rtm) return;

			await rtm.subscribe(privateChannel?.message?.channelName);

			const message = JSON.stringify({
				type: 'call-invitation-declined',
				doctor_uid: privateChannel?.message?.doctor_uid,
				patient_uid: privateChannel?.message?.patient_uid,
				channelName: privateChannel?.message?.channelName
			});

			// Send a private message to the user with the UserID "Lily"
			await rtm.publish(privateChannel?.message?.channelName, message);
		} catch (error) {
			console.error('Error declining call invitation:', error);
		}
	};

	const notAnsweringCall = async (privateChannel) => {
		if (!rtm) return;

		const message = JSON.stringify({
			type: 'call-not-answered',
			doctor_uid: privateChannel?.message?.doctor_uid,
			patient_uid: privateChannel?.message?.patient_uid,
			channelName: agoraConfig?.channelName
		});

		// Send a private message to the user with the UserID "Lily"
		await rtm.publish(privateChannel?.message?.channelName, message);
	};

	const endVideoCall = async (privateChannel) => {
		if (!rtm) return;

		const message = JSON.stringify({
			type: 'call-end',
			doctor_uid: privateChannel?.message?.doctor_uid,
			patient_uid: privateChannel?.message?.patient_uid,
			channelName: privateChannel?.message?.channelName
		});

		// Send a private message to the user with the UserID "Lily"
		await rtm.publish(privateChannel?.message?.channelName, message);
	};

	const testMessage = async (text) => {
		try {
			if (!rtm) return;

			const message = JSON.stringify({
				type: 'test-message',
				message: text
			});
			await rtm.publish(agoraConfig?.channelName, message);
		} catch (error) {
			console.error('Error sending Message:', error);
		}
	};

	const setUserStatus = async (status, channelName, channelType) => {
		try {
			// if (!rtm) return;

			const status = { mood: 'pumped', isTyping: 'false' };
			const result = await rtm.presence.setState(agoraConfig?.channelName, 'MESSAGE', status);
			console.log('Set User Status:', result);
		} catch (error) {
			console.error('Error setting user status:', error);
		}
	};

	const getUserStatus = async (userId, channelName, channelType) => {
		try {
			const result = await rtm.presence.getState(userId, channelName, channelType);
			console.log('Get User State:', result);
		} catch (error) {
			console.log(error);
		}
	};

	const removeUserStatus = async (channelName, channelType) => {
		try {
			const result = await rtm.presence.removeState(channelName, channelType);
			console.log('Remove User State:', result);
		} catch (error) {
			console.error('Error removing user status:', error);
		}
	};

	return (
		<AgoraSignalingContext.Provider
			value={{
				rtm,
				isDoctorLoggedIn,
				setIsDoctorLoggedIn,
				isPatientLoggedIn,
				signalDoctorLogin,
				signalPatientLogin,
				signalLogout,
				sendCallInvitation,
				acceptCallInvitation,
				declineCallInvitation,
				notAnsweringCall,
				endVideoCall,
				unsubscribeChannel,
				eventCall,
				messageCall,
				getUserSubscribeChannels,
				getOnlineUsersInChannel,
				setUserStatus,
				getUserStatus,
				testMessage,
				openOnlineOfflineModal,
				handleOpenOnlineOfflineModal,
				handleCloseOnlineOfflineModal
			}}>
			{children}
		</AgoraSignalingContext.Provider>
	);
};

const useAgoraSignalling = () => {
	const agoraSignalingContext = useContext(AgoraSignalingContext);
	return !agoraSignalingContext && typeof window !== 'undefined' ? {} : agoraSignalingContext;
};

export default useAgoraSignalling;
